<template>
  <section>

    <v-dialog v-model="show" persistent scrollable max-width="520px">
      <v-card>

        <v-card-title>
          <command-panel
              title="Файлы"
              :close-button="true"
              :delete-selected-button="true"
              @close="$emit('close')"
              @deleteSelected="confirmDeleteSelected"
          />
        </v-card-title>

        <v-card-text>
          <template>
            <vue-good-table
                :is-loading="loading"
                :columns="columns"
                :rows="list"
                :line-numbers="true"
                :fixed-header="false"
                :search-options="{ enabled: false }"
                :sort-options="{ enabled: false }"
                :pagination-options="{ enabled: false }"
                @on-row-click=""
                @on-cell-click="onCellClick"
                :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              disableSelectInfo: true,
            }"
                @on-selected-rows-change="selectedRowsChanged"
            >
            </vue-good-table>
          </template>
        </v-card-text>

      </v-card>
    </v-dialog>

    <confirm
        v-if="showConfirmDelete"
        header="Удалить отмеченные?"
        @ok="showConfirmDelete=false; deleteSelected()"
        @cancel="showConfirmDelete=false"
    />

    <photo-item
        v-if="showPhotoItem"
        :id="selectedFile.id"
        :src="selectedFile.src"
        @close="showPhotoItem=false"
        @deleteFiles="deleteFiles"
    />

    <audio-item
        v-if="showAudioItem"
        :id="selectedFile.id"
        :src="selectedFile.src"
        @close="showAudioItem=false"
        @deleteFiles="deleteFiles"
    />

  </section>
</template>

<script>
import CommandPanel from "@/components/common/commandPanel";
import Confirm from "@/components/common/confirm";
import PhotoItem from "@/components/common/photoItem";
import AudioItem from "@/components/common/audioItem";
import common from "@/common";
import {v4 as uuidv4} from "uuid";
import localForage from "localforage";

export default {
  auth: true,
  name: "filesList",

  components: {AudioItem, PhotoItem, CommandPanel, Confirm},

  props: {
    holderId: null,
  },

  created() {
    this.loadData()
  },

  data() {
    return {
      show: true,
      showConfirmDelete: false,
      loading: false,
      list: [],
      columns: [
        {
          label: "Файл",
          field: "name",
          sortable: true,
        },
      ],
      selectedRows: [],
      showPhotoItem: false,
      showAudioItem: false,
      selectedFile: null,
    }
  },

  methods: {
    async loadData() {
      this.list = await common.getFiles(this.holderId)
    },

    selectedRowsChanged(params) {
      this.selectedRows = params.selectedRows
    },

    async onCellClick(params) {
      if (params.row.fileType === 'image') {
        this.selectedFile = params.row
        this.showPhotoItem = true
      } else if (params.row.fileType === 'audio') {
        this.selectedFile = params.row
        this.showAudioItem = true
      }
    },

    confirmDeleteSelected() {
      if (this.selectedRows.length) this.showConfirmDelete = true
    },

    async deleteSelected() {
      this.showFilesList = false

      let files = await localForage.getItem('files')
      for (let el of this.selectedRows) {
        files = files.filter(f=>f.id!==el.id)
      }
      await localForage.setItem('files', files)
      this.list = await common.getFiles(this.holderId)
      this.selectedRows = []
      this.$emit('deleteFiles')
    },

    async deleteFiles() {
      this.list = await common.getFiles(this.holderId)
      this.selectedRows = []
      this.$emit('deleteFiles')
    },

  },

}
</script>
