<template>
  <div>
    <br>
    <v-card class="mx-auto rounded-lg">
      <p v-if="!investPlaceCount" class="text-center text-h5 text--secondary font-weight-bold">Точки пока не зарегистрированы</p>
      <p v-else class="text-center text-h5 text--secondary font-weight-bold">Зарегистрировано<br>точек</p>
      <p class="text-center text-h2 text--secondary font-weight-bold">{{investPlaceCount}}</p>
      <v-card-actions>
        <v-btn small color="info" dark @click="$emit('investPlaceList')" class="rounded-lg">Список</v-btn>
        <v-spacer/>
        <v-btn small color="info" dark @click="$emit('investPlaceItem')" class="rounded-lg">Создать</v-btn>
      </v-card-actions>
    </v-card>
    <br><br>

    <div style="text-align: center">
      <span class="text-center text-subtitle-1 text--secondary font-weight-bold">Инструкция</span>
    </div>
      <v-divider/>
    <br>
      <p class="text-center text-subtitle-2 text--secondary">1. Для создания описания точки нажмите кнопку «Создать»</p>
      <p class="text-center text-subtitle-2 text--secondary">2. Для просмотра списка точек и корректировки данных нажмите кнопку «Список»</p>
      <p class="text-center text-subtitle-2 text--secondary">3. Для выгрузки данных на портал перейдите в главное меню</p>
  </div>
</template>

<script>
import common from "@/common";
import localForage from "localforage";

export default {
  name: 'mainPage',

  props: {
    param: String,
  },

  created() {
    this.loadData()
  },

  data() {
    return {
      itemType: 'investPlace',
      investPlaceCount: 0,
    }
  },

  methods: {
    async loadData() {
      this.investPlaceCount = await common.getListLength(this.itemType)
    }
  },

}
</script>
<script setup>
</script>