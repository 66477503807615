<template>
  <div style="margin-top: -10px">

    <v-banner single-line>

      {{ title }}

      <template v-slot:actions>

        <v-btn class="rounded-lg" v-if="okButton" @click="$emit('ok')" color="primary">
          <v-icon>mdi-check</v-icon>&nbsp;Ок
        </v-btn>

        <v-btn class="rounded-lg" v-if="createButton" @click="$emit('create')" color="primary">
          <v-icon>mdi-playlist-plus</v-icon><span v-if="$vuetify.breakpoint.mdAndUp">&nbsp;Новый</span>
        </v-btn>

        <v-btn class="rounded-lg" v-if="saveButton" @click="$emit('save')" :disabled="!saveEnabled" color="primary">
          <v-icon>mdi-content-save</v-icon>
        </v-btn>

        <v-btn class="rounded-lg" v-if="loadButton" @click="$emit('load')" :disabled="!saveEnabled" color="primary">
          <span>Загрузить</span>
        </v-btn>

        <v-menu v-if="showMenu" :offset-y=true>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="rounded-lg" color="cyan" v-bind="attrs" v-on="on" dark>
              <v-icon>mdi-menu</v-icon><span v-if="$vuetify.breakpoint.mdAndUp">&nbsp;Меню</span>
            </v-btn>
          </template>
          <v-list>

            <v-list-item v-if="photoButton" :key="14" @click="$emit('photo')" link align="left">
              <v-list-item-title>
                <v-icon>mdi-camera</v-icon>&nbsp;Фотография
              </v-list-item-title>
            </v-list-item>

            <v-list-item v-if="voiceRecordButton" :key="15" @click="$emit('voiceRecord')" link align="left">
              <v-list-item-title>
                <v-icon>mdi-microphone</v-icon>&nbsp;Аудиозапись
              </v-list-item-title>
            </v-list-item>

            <v-list-item v-if="fileButton" :key="7" @click="$emit('file')" link align="left">
              <v-list-item-title>
                <v-icon>mdi-paperclip</v-icon>&nbsp;Файлы
              </v-list-item-title>
            </v-list-item>

            <v-divider v-if="deleteButton" />
            <v-list-item v-if="deleteButton" :key="100" @click="$emit('delete')" link align="left">
              <v-list-item-title>
                <v-icon color="red">mdi-trash-can-outline</v-icon>&nbsp;<span style="color: red">Удалить</span>
              </v-list-item-title>
            </v-list-item>

          </v-list>
        </v-menu>

        <v-btn class="rounded-lg" v-if="updateButton" @click="$emit('update')" color="primary">
          <v-icon>mdi-reload</v-icon><span v-if="$vuetify.breakpoint.mdAndUp">&nbsp;Обновить</span>
        </v-btn>

        <v-btn class="rounded-lg" v-if="deleteSelectedButton" @click="$emit('deleteSelected')" color="red" dark>
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>

        <v-btn class="rounded-lg" v-if="cancelButton" @click="$emit('cancel')" color="blue-grey lighten-1" dark>
          Отмена
        </v-btn>

        <v-btn class="rounded-lg" v-if="closeButton" @click="$emit('close')" color="blue-grey lighten-1" dark>
          Закр.
        </v-btn>

      </template>

    </v-banner>

    <div style="height: 5px"></div>

  </div>
</template>

<script>
export default {
  name: "commandPanel",

  props: {
    title: "",
    saveEnabled:          false,

    selectAllButton:      false,
    unselectAllButton:    false,

    okButton:             false,
    cancelButton:         false,

    createButton:         false,
    createFolderButton:   false,
    saveButton:           false,
    saveCloseButton:      false,
    updateButton:         false,

    // Меню
    fileButton:           false,
    loadButton:           false,
    deleteButton:         false,
    photoButton:          false,
    voiceRecordButton:    false,
    //

    deleteSelectedButton: false,
    closeButton:          false,
  },

  data() {
    return {
      showMenu:
           this.fileButton
        || this.deleteButton
        || this.photoButton
        || this.voiceRecordButton
    }
  },

}
</script>
