import Vue from 'vue'
import App from './App.vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import VueGoodTablePlugin from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import './registerServiceWorker'
import localForage from 'localforage';

// Vue.config.productionTip = false
Vue.use(Vuetify)
Vue.use(VueGoodTablePlugin)

new Vue({
  vuetify : new Vuetify(),
  render: h => h(App),
}).$mount('#app')

// Инициализация хранилища
localForage.getItem('investPlace').then(value=>{
  if (!value) localForage.setItem('investPlace', [])
})
localForage.getItem('files').then(value=>{
  if (!value) localForage.setItem('files', [])
})
