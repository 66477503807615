let required = () => {
  return v => v && v.length > 0 || "Должно быть заполнено"
}

let minLength = (minLength) => {
  return v => v && v.length >= minLength || `Должно быть не менее ${minLength} символов`
}

let maxLength = (maxLength) => {
  return v => !v || v.length <= maxLength || `Должно быть не более ${maxLength} символов`
}

let emailFormat = () => {
  return v => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Введите корректный E-mail"
}

let maxFileSize = (maxSizeMB) =>{
  return v => !v || v.size <= maxSizeMB*1024*1024 || "Размер файла должен быть не более "+maxSizeMB+" MB!"
}

let fileNameRequired = () => {
  return v => v && v.name.length > 0 || "Должно быть заполнено"
}

let objectRequired = () => {
  return v => !!v || "Должно быть заполнено"
}

export default {
  required,
  minLength,
  maxLength,
  emailFormat,
  maxFileSize,
  fileNameRequired,
  objectRequired,
}
