<template>
  <section>
    <template>
      <v-btn @click="getCurrentPosition()" color="primary" dark fab small>
        <v-icon>mdi-cellphone-marker</v-icon>
      </v-btn>
    </template>
  </section>
</template>

<script>
export default {
  name: "getCurrentPosition",

  props: {
    obj: Object,
  },

  methods: {
    getCurrentPosition() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.obj.lat = position.coords.latitude
          this.obj.lon = position.coords.longitude
        },
        function (error) {
          alert(error.message)
        }
      )
    },

  },
}
</script>
