<template>
  <v-dialog v-model="show" persistent max-width="400px">
    <v-card>
      <v-card-title class="headline">{{ header }}</v-card-title>
      <v-card-text>{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn @click="$emit('ok')" color="primary" small>
          <v-icon>mdi-check</v-icon>OK
        </v-btn>

        <v-btn @click="$emit('cancel')" color="blue-grey lighten-1" dark small>
          Отмена
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "confirm",

  props: {
    header: "",
    text: "",
  },

  data() {
    return {
      show: true
    }
  },
}
</script>
