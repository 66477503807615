<template>
  <div>
    <template>
      <vue-good-table
          :is-loading="loading"
          :columns="columns"
          :rows="list"
          :line-numbers="true"
          :fixed-header="false"
          :search-options="{ enabled: true, placeholder: 'Найти' }"
          :sort-options="{ enabled: true, initialSortBy: { field: 'name'} }"
          no-results-text = "Список пуст"
          no-data-text = "ioip"
          hide-default-footer
          @on-row-click="onRowClick"
      >
        <div slot="emptystate" style="text-align: center"><span>Список пуст</span></div>
        <div slot="table-actions" class="vgt-pull-right">
          <v-btn small color="info" dark @click="$emit('investPlaceItem')" class="rounded-lg">Создать</v-btn>
        </div>
      </vue-good-table>
    </template>
  </div>
</template>

<script>
import common from "@/common";
import localForage from "localforage";
import {v4 as uuidv4} from "uuid";

export default {

  props: {
    param: String,
  },

  created() {
    this.loadData()
  },

  data() {
    return {
      itemType: 'investPlace',
      loading: false,
      list: [],
      columns: [
        { label: "", field: "name", sortFn: this.sortFn },
      ],
    }
  },

  methods: {

    async loadData() {
      this.list = await common.getList(this.itemType)
    },

    async onRowClick(params) {
      this.$emit('investPlaceItem', params.row.id)
    },

    sortFn(x, y, col, rowX, rowY) {
      return (x < y ? -1 : (x > y ? 1 : 0))
    },

  },

}
</script>
