import { render, staticRenderFns } from "./audioItem.vue?vue&type=template&id=703f606c&"
import script from "./audioItem.vue?vue&type=script&lang=js&"
export * from "./audioItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports