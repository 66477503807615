<template>
  <v-carousel height="382" hide-delimiters cycle bo>
    <v-carousel-item v-for="(img, i) in images" :key="i">
      <v-sheet height="100%" color="transparent">
        <v-row class="fill-height" align="center" justify="center">

          <v-img
              :src="img.src"
              style="border-radius: 8px"
              contain
              max-width="94%"
              max-height="94%"
          />

        </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<script>

export default {
  name: "photoCarousel",
  props: {
    images: Array,
  },
}
</script>


