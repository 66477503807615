<template>
  <section>

  <v-dialog v-model="show" persistent scrollable max-width="520px">
    <v-card>

      <v-card-title>
        <command-panel
          title="Аудио"
          :close-button="true"
          :delete-selected-button="true"
          @close="$emit('close')"
          @deleteSelected="confirmDeleteItem"
        />
      </v-card-title>

      <v-card-text>
        <v-sheet height="300" color="transparent">
          <v-row class="fill-height" align="center" justify="center">

            <audio
                :src="src"
                controls
            />

          </v-row>
        </v-sheet>
      </v-card-text>
    </v-card>
  </v-dialog>

  <confirm
    v-if="showConfirmDelete"
    header="Удалить ?"
    @ok="showConfirmDelete=false; deleteItem()"
    @cancel="showConfirmDelete=false"
  />

  </section>
</template>

<script>
import CommandPanel from "@/components/common/commandPanel";
import Confirm from "@/components/common/confirm";
import common from "@/common";

export default {
  auth: true,
  name: "audioItem",

  components: { CommandPanel, Confirm },

  props: {
    id: String,
    src: String,
  },

  data() {
    return {
      show: true,
      showConfirmDelete: false,
    }
  },

  methods: {

    confirmDeleteItem() {
      this.showConfirmDelete=true
    },

    async deleteItem() {
      await common.deleteFile(this.id)
      this.$emit('close')
      this.$emit('deleteFiles')
    },

  },

}
</script>
