<template>
  <v-app :style="{ backgroundImage: 'url(/bg-4.jpg)', backgroundSize: 'cover' }">
    <div id="app">
      <v-app-bar clipped-left fixed app src="/bg-5.jpg">
        &nbsp;
        <v-menu bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" color="info">
              <v-icon large>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item :key="1" @click="showUpload=true" align="left">
              <v-list-item-title>Выгрузить на сайт</v-list-item-title>
            </v-list-item>
            <v-list-item :key="2" @click="showClearLocalStorage=true" align="left">
              <v-list-item-title>Очистить локальное хранилище</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        &nbsp;&nbsp;
        <v-toolbar-title class="text-h5 font-weight-medium text--secondary">Точки роста</v-toolbar-title>
        <v-spacer/>
        <v-btn v-if="page!=='mainPage'" icon color="info" @click="page='mainPage'">
          <v-icon large>mdi-arrow-left-circle</v-icon>
        </v-btn>
        &nbsp;&nbsp;
      </v-app-bar>

      <v-container style="margin-top: 70px;">

          <component
              :is="page"
              :param="param"

              @mainPage="page='mainPage'"

              @investPlaceList="page='investPlaceList'"
              @investPlaceItem="investPlaceItem"

          />
        </v-container>

      <v-footer app height="25px" style="background-color: #E0F7FA">
        <v-spacer/>
        <span class="text--secondary" style="margin-top: -3px; font-size: 12px;">
        <b> &copy; ООО "Энерго-Решение". 2023 Версия 2.1.4</b>
      </span>
        <v-spacer/>
      </v-footer>

      <confirm
          v-if="showClearLocalStorage"
          header="Удалить все данные?"
          text="Будет очищено локальное хранилище"
          @ok="showClearLocalStorage = false;clearLocalStorage();"
          @cancel="showClearLocalStorage = false"
      />

      <upload
          v-if="showUpload"
          @close="reloadMainPage(); showUpload = false;"
      />

    </div>
  </v-app>
</template>

<script>
import common from "@/common";
import confirm from "@/components/common/confirm";

import mainPage from "@/components/mainPage";

import investPlaceList from "@/components/investPlaceList.vue";
import investPlaceItem from "@/components/investPlaceItem.vue";

import upload from './components/common/upload.vue';

export default {
  name: 'App',
  components: {
    investPlaceList,
    investPlaceItem,
    confirm,
    mainPage,
    upload,
  },

  data() {
    return {
      page: "mainPage",
      param: "",
      showClearLocalStorage: false,
      showUpload: false,
    }
  },

  methods: {
    investPlaceItem(id) {
      this.param = id
      this.page = 'investPlaceItem'
    },

    clearLocalStorage() {
      common.clearLocalStorage()
      this.reloadMainPage()
    },

    reloadMainPage() {
      this.page = ''
      this.$nextTick(()=>{this.page = 'mainPage'})
    },
  },

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}
</style>
