import {v4 as uuidv4} from "uuid";
import localForage from 'localforage';

export default {

  async getList(itemType) {
    return await localForage.getItem(itemType)
  },

  async getListLength(itemType) {
    const list = await localForage.getItem(itemType)
    return list.length
  },

  async getItem(type, id) {
    const list = await localForage.getItem(type)
    return list.find(el=>el.id===id)
  },

  async getImages(holderId) {
    const files = await localForage.getItem('files')
    return files.filter(el=>el.holderId===holderId && el.fileType==='image')
  },

  async getFiles(holderId) {
    const files = await localForage.getItem('files')
    return files.filter(el=>el.holderId===holderId)
  },

  async saveItem(itemType, item) {
    const list = await localForage.getItem(itemType)
    const find = list.find(el => el.id === item.id)
    if (find) Object.assign(find, item)
    else list.push(item)
    await localForage.setItem(itemType, list)
  },

  async deleteFile(fileId) {
    let files = await localForage.getItem('files')
    files = files.filter(el=>el.id!==fileId)
    await localForage.setItem('files', files)
  },

  async deleteItem(itemType, itemId) {

    // 1. Удалим объект
    let list = await localForage.getItem(itemType)
    list = list.filter(el=>el.id !== itemId)
    await localForage.setItem(itemType, list)

    // 2. Удалим файлы
    let files = await localForage.getItem('files')
    files = files.filter(el=>el.holderId!==itemId)
    await localForage.setItem('files', files)

  },

  async savePhoto(holderType, holderId, file, ctx) {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = async (event) => {
      const files = await localForage.getItem('files')
      files.push({
        id: uuidv4(),
        name: 'Фото_'+Date.now(),
        holderType: holderType,
        holderId: holderId,
        fileType: 'image',
        description: 'Фото',
        createdAt: (new Date).toLocaleString(),
        src: event.target.result,
      })
      await localForage.setItem('files', files)
      ctx.images = this.getImages(holderId)
    }
  },

  async saveVoiceRecord(holderType, holderId, voice) {
    const reader = new FileReader()
    const blob = new Blob(voice, {type: 'audio/wav'})
    reader.readAsDataURL(blob)
    reader.onload = async (event) => {
      const files = await localForage.getItem('files')
      files.push({
        id: uuidv4(),
        name: 'Аудио_' + Date.now(),
        holderType: holderType,
        holderId: holderId,
        fileType: 'audio',
        description: "Аудио",
        createdAt: (new Date).toLocaleString(),
        src: event.target.result,
      })
      await localForage.setItem('files', files)
    }
  },


  // async savePhoto(holderType, holderId, file, ctx) {
  //     const reader = new FileReader()
  //     reader.readAsDataURL(file)
  //     reader.onload = (event) => {
  //       const files = JSON.parse(localStorage.getItem('files'))
  //       files.push({
  //         id: uuidv4(),
  //         name: 'Фото_'+Date.now(),
  //         holderType: holderType,
  //         holderId: holderId,
  //         fileType: 'image',
  //         description: 'Фото',
  //         createdAt: (new Date).toLocaleString(),
  //         src: event.target.result,
  //       })
  //       localStorage.setItem('files', JSON.stringify(files))
  //       ctx.images = this.getImages(holderId)
  //     }
  // },
  //
  // async saveVoiceRecord(holderType, holderId, voice) {
  //   const reader = new FileReader()
  //   const blob = new Blob(voice, {type: 'audio/wav'})
  //   reader.readAsDataURL(blob)
  //   reader.onload = (event) => {
  //     const files = JSON.parse(localStorage.getItem('files'))
  //     files.push({
  //       id: uuidv4(),
  //       name: 'Аудио_' + Date.now(),
  //       holderType: holderType,
  //       holderId: holderId,
  //       fileType: 'audio',
  //       description: "Аудио",
  //       createdAt: (new Date).toLocaleString(),
  //       src: event.target.result,
  //     })
  //     localStorage.setItem('files', JSON.stringify(files))
  //   }
  // },

  async clearLocalStorage() {
    await localForage.setItem('investPlace', [])
    await localForage.setItem('files', [])
  },

}