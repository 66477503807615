<template>
  <section>

  <v-dialog v-model="show" persistent scrollable max-width="560px">
    <v-card>

      <v-card-title>
        <command-panel
          title="Выгрузка на сайт"
        />
      </v-card-title>
      <v-card-text>
        <br>

        <template v-if="authForm">
          <v-text-field
              dense
              label="Логин"
              type="text"
              v-model="email"
              :rules="[required()]"
              autocomplete="off"
              clearable
          ></v-text-field>
          <v-text-field
              dense
              label="Пароль"
              type="password"
              v-model="password"
              :rules="[required()]"
              autocomplete="off"
              clearable
          ></v-text-field>
          <p class="text-h6" style="text-align: center">Внимание!</p>
          <p class="text-subtitle-1" style="text-align: center">После успешной выгрузки локальное хранилище данных будет автоматически очищено</p>
        </template>

        <template v-if="loading">
          <p class="text-h6">Выполняется выгрузка<br>Подождите...</p>
          <div style="text-align: center">
            <v-progress-linear
                color="info"
                indeterminate
                rounded
                height="10"
            ></v-progress-linear>
          </div>
        </template>

        <template v-if="completed">
          <p class="text-h6">{{ result }}</p>
          <div v-html="description"></div>
        </template>

      </v-card-text>

      <v-card-actions v-if="authForm">
        <v-spacer></v-spacer>
        <v-btn @click="upload" color="primary" small :disabled="(!email||!password)">
          <v-icon>mdi-check</v-icon>OK
        </v-btn>
        <v-btn @click="$emit('close')" color="blue-grey lighten-1" dark small>
          Отмена
        </v-btn>
      </v-card-actions>

      <v-card-actions v-if="completed">
        <v-spacer></v-spacer>
        <v-btn @click="$emit('close')" color="blue-grey lighten-1" dark small>
          Закрыть
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

  </section>
</template>

<script>
import common from "@/common";
import CommandPanel from "@/components/common/commandPanel";
import Confirm from "@/components/common/confirm";
const axios = require('axios').default;
import validations from "@/validations";

export default {
  auth: true,
  name: "upload",

  components: { CommandPanel, Confirm },

  data() {
    return {
      show: true,
      email: null,
      password: '',
      authForm: true,
      loading: false,
      completed: false,
      result: '',
      description: '',
      ...validations,
    }
  },

  created() {
    this.email = localStorage.getItem('email') || null
  },

  methods: {

    async upload() {
      localStorage.setItem('email', this.email)
      try {
        this.authForm = false
        this.loading = true

        // 1. Отправим массив точек роста
        let dataPac = {
          email: this.email,
          password: this.password,
          investPlace: await common.getList('investPlace'),
        }
        // let response = await axios.post('http://localhost:5000/investPlace/upload',
        let response = await axios.post('https://api.gov.enerso.ru/investPlace/upload',
            dataPac,
            {headers: {"Content-Type": "application/json"}},
        )

        // Если не все точки выгрузились, то дальше не идем
        if (!response.data.uploadCompleted) {
          this.loading = false
          this.completed = true
          this.result = 'Ошибка выгрузки!'
          this.description = 'Попробуйте повторить выгрузку позже'
          return
        }

        // 2. Отправим фотографии по одной
        const files = await common.getList('files')
        for (const [i, el] of files.entries()) {
          dataPac = {
            email: this.email,
            password: this.password,
            files: [el],
          }
          // response = await axios.post('http://localhost:5000/investPlace/upload',
              response = await axios.post('https://api.gov.enerso.ru/investPlace/upload',
              dataPac,
              {headers: {"Content-Type": "application/json"}},
          )
        }

        // Если не все файлы выгрузились, то дальше не идем
        if (!response.data.uploadCompleted) {
          this.loading = false
          this.completed = true
          this.result = 'Ошибка выгрузки!'
          this.description = 'Попробуйте повторить выгрузку позже'
          return
        }

        this.loading = false
        this.completed = true
        this.result = 'Выгрузка завершена!'
        this.description = response.data.message

        await common.clearLocalStorage()

      } catch (error) {
        this.loading = false
        this.completed = true
        this.result = 'Произошла ошибка!'
        this.description = error.message
        if (error.message==='Request failed with status code 401') this.description+=' (ошибка авторизации)'
      }
    },

  },

}
</script>
