<template>
  <v-dialog v-model="show" persistent max-width="270px">
    <v-card :loading="loading">
      <v-card-title class="headline"><span :style="{color: color}">{{title}}</span></v-card-title>

      <v-card-actions>
        <v-spacer/>
        <v-btn v-if="!onAir && !voice.length" @click="start()" color="info" dark small>
          <v-icon>mdi-microphone</v-icon>
          Начать
        </v-btn>
        <v-btn v-if="onAir" @click="finish()" color="info" dark small>
          <v-icon>mdi-microphone-off</v-icon>
          Закончить
        </v-btn>

        &nbsp;&nbsp;&nbsp;
        <v-btn v-if="voice.length" @click="$emit('ok', voice)" color="primary" small>
          <v-icon>mdi-content-save</v-icon>
          Сохранить
        </v-btn>

        <v-btn @click="cancel()" color="blue-grey lighten-1" dark small>
          Отмена
        </v-btn>
        <v-spacer/>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "voiceRecorder",

  data() {
    return {
      show: true,
      title: "Аудиозапись",
      onAir: false,
      loading: false,
      voice: [],
      color: "black",
      mediaRecorder: null,
    }
  },

  methods: {

    start() {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          this.mediaRecorder = new MediaRecorder(stream)
          this.mediaRecorder.start()

          this.title = "  Идет запись..."
          this.color ="#f87979"
          this.loading = true
          this.onAir = true

          this.mediaRecorder.addEventListener("dataavailable", (event) => {
            this.voice.push(event.data)
          })

        })
        .catch(e => {
          this.mediaRecorder = null
          alert("Микрофон отсутствует или отключен!")
        })
    },

    finish() {
      this.mediaRecorder.stop()
      this.title = "Аудиозапись"
      this.color ="black"
      this.loading = false
      this.onAir = false
    },

    cancel() {
      if (this.mediaRecorder && this.mediaRecorder.state === 'recording') this.mediaRecorder.stop()
      this.$emit('cancel')
    },

  },
}
</script>
