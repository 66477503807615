<template>
  <div >
    <command-panel
        title="Точка роста"
        :save-button="true"
        :save-enabled="valid"
        :delete-button="true"
        :photo-button="true"
        :voice-record-button="false"
        :file-button="true"
        @save="saveItem"
        @delete="showConfirmDelete=true"
        @photo="makePhoto"
        @voiceRecord="showVoiceRecorder=true"
        @file="showFilesList=true"
    />
    <br>
    <v-form v-model="valid">
      <v-card :loading="loading">
        <v-card-text>

          <v-text-field
              dense
              label="Наименование (обязательное поле)"
              type="text"
              v-model="model.name"
              :rules="[required()]"
              autocomplete="off"
          ></v-text-field>

          <v-text-field
              dense
              label="Ближайший населенный пункт"
              type="text"
              v-model="model.locality"
              autocomplete="off"
          ></v-text-field>

          <v-row align="center" dense>
            <v-col cols="5" sm="5">
              <v-text-field
                  dense
                  label="Широта"
                  type="text"
                  v-model="model.lat"
                  autocomplete="off"
              ></v-text-field>
            </v-col>
            &nbsp;
            <v-col cols="5" sm="5">
              <v-text-field
                  dense
                  label="Долгота"
                  type="text"
                  v-model="model.lon"
                  autocomplete="off"
              ></v-text-field>
            </v-col>
            &nbsp;
            <get-current-position :obj="model" />
          </v-row>

<!--          <v-autocomplete-->
<!--              dense-->
<!--              label="Тип"-->
<!--              v-model="model.type"-->
<!--              :items="type"-->
<!--              :clearable="true"-->
<!--              @click:clear="$nextTick(() => model.type=null)"-->
<!--          ></v-autocomplete>-->

<!--          <v-radio-group v-model="model.height" row label="Высота (м):" dense>-->
<!--            <v-radio label="9" :value=9></v-radio>-->
<!--            <v-radio label="11" :value=11></v-radio>-->
<!--          </v-radio-group>-->

          <photo-carousel :images="images" v-if="images.length"/>

          <v-textarea
              dense
              label="Описание"
              v-model="model.description"
              autocomplete="off"
          ></v-textarea>

        </v-card-text>
      </v-card>
    </v-form>

    <input type="file" accept="image/*" capture="environment" id="photoInput" @change="savePhoto" hidden>

    <voice-recorder
        v-if="showVoiceRecorder"
        @ok="saveVoiceRecord"
        @cancel="showVoiceRecorder=false"
    />

    <confirm
        v-if="showConfirmDelete"
        header="Удалить?"
        :text="model.name"
        @ok="showConfirmDelete = false; deleteItem();"
        @cancel="showConfirmDelete = false"
    />

    <files-list
        v-if="showFilesList"
        :holder-id="model.id"
        @close="showFilesList=false"
        @deleteFiles="deleteFiles"
    />

  </div>
</template>

<script>
import CommandPanel from "@/components/common/commandPanel";
import validations from "@/validations";
import Confirm from "@/components/common/confirm";
import GetCurrentPosition from "@/components/common/getCurrentPosition";
import PhotoCarousel from "@/components/common/photoCarousel";
import {v4 as uuidv4} from "uuid";
import VoiceRecorder from "@/components/common/voiceRecorder";
import FilesList from "@/components/common/filesList";
import common from "@/common";
import {markRaw} from "vue";

export default {

  components: { FilesList, VoiceRecorder, PhotoCarousel, GetCurrentPosition, Confirm, CommandPanel },

  props: {
    param: String, // Может быть передан id объекта, если редактируем или пустое значение для новых
  },

  created() {
    this.loadData()
  },

  data() {
    return {
      itemType: 'investPlace',
      loading: false,
      valid: false,
      images: [],
      showConfirmDelete: false,
      showVoiceRecorder: false,
      showFilesList: false,
      src: null,
      ...validations,
      model: {
        id: null,
        name: null,
        locality: null,
        lat: null,
        lon: null,
        description: null,
      },
    }
  },

  methods: {
    async loadData() {
      const item = await common.getItem(this.itemType, this.param)
      if (item) {
        Object.assign(this.model, item)
        this.images = await common.getImages(this.model.id)
      }
      else this.model.id = uuidv4()

    },

    async saveItem() {
      this.loading = true
      this.model.description = this.model.description || ""
      await common.saveItem(this.itemType, this.model)
      this.loading = false
      this.$emit('mainPage')
    },

    async deleteItem() {
      await common.deleteItem(this.itemType, this.model.id)
      this.$emit('mainPage')
    },

    async makePhoto() {
      document.getElementById('photoInput').click()
    },

    async savePhoto(event) {
      if (event.target.files.length) {
        await common.savePhoto(this.itemType, this.model.id, event.target.files[0], this)
        setTimeout(async ()=>{
          this.images = await common.getImages(this.model.id)}, 300)
      }
    },

    async saveVoiceRecord(voice) {
      this.showVoiceRecorder = false
      if (voice && Array.isArray(voice) && voice.length) {
        await common.saveVoiceRecord(this.itemType, this.model.id, voice)
      }
    },

    async deleteFiles() {
      this.images = await common.getImages(this.model.id)
    },

  },
}
</script>
